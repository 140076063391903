import { createWebHistory, createRouter } from "vue-router"
import Main from "@/components/Main/"
import Congrats from '@/components/Congrats'
import Instruction from '@/components/Instruction'
import EmailTaken from '@/components/EmailTaken'
import Registration from '@/components/Registration'
import PaymentError from '@/components/PaymentError'
import NotFound from '@/components/404/'

const routes = [
  {
    path: "/",
    name: 'Main',
    component: Main,
    meta: {
      title: 'FindMe'
    },
  }, {
    path: "/retry",
    name: 'Retry',
    component: Main,
    meta: {
      title: 'Retry payment'
    },
  }, {
    path: "/congrats",
    name: 'Congrats',
    component: Congrats,
    meta: {
      title: 'Congrats'
    },
  }, {
    path: "/instruction",
    name: 'Instruction',
    component: Instruction,
    meta: {
      title: 'Instruction'
    },
  },{
    path: "/canceled",
    name: 'Payment failed',
    component: PaymentError,
    meta: {
      title: 'Payment failed'
    },
  }, {
    path: "/register/:user_id",
    name: 'Payment succeed',
    component: Registration,
    meta: {
      title: 'Payment succeed'
    },
  }, {
    path: "/register/:user_id/true",
    name: 'Email taken',
    component: EmailTaken,
    meta: {
      title: 'Email taken'
    },
  }, {
    name: '404',
    path: '/404',
    component: NotFound,
    meta: {
      title: 'Page not found'
    }
  }, {
    path: '/:pathMatch(.*)*',
    redirect: { name: '404' }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
});

router.beforeEach(to => {
  document.title = to.meta.title
})

export default router;

<template>
  <div :style="appStyle" @touchmove="touchMove">
    <Swiper
      :slides-per-view="1"     
      :space-between="0"
      :direction="'vertical'"  
      :height="sliderHeight"
      :noSwipingClass="'no-swipe'"
      :initialSlide="initialSlide"
      :followFinger="false"
      :speed="500"
      @realIndexChange="handleScreenChange"
      @slideChangeTransitionStart="toggleProceedButton(false)"
      @slideChangeTransitionEnd="toggleProceedButton(true)"
      @swiper="initSwiper">
      <SwiperSlide v-for="(screen, index) in screens" :key="index">
        <DynamicScreen
          :activeComponent="screen"
          :activeSlideIndex="activeSlideIndex" 
          :product="product"
          :screenIndex="index"/>
      </SwiperSlide>
    </Swiper>
    <div class="proceed" :class="{'displayed' : isButtonDisplayed}" @click="scrollSwiper" v-show="this.activeSlideIndex !== 7">
      <div class="proceed__button button">Start Free Trial</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
SwiperCore.use([Navigation, Pagination, A11y]);


import DynamicScreen from '@/components/Screens/DynamicScreen'
import LandingServices from '@/services/LandingServices.js'

export default {
  data() {
    return {
      initialSlide: 0,
      sliderHeight: 0,
      swiper: null,
      innerHeight: 0,
      activeSlideIndex: null,
      screens: [
        'ScreenMain',
        'ScreenBrowse',
        'ScreenNotifications',
        'ScreenCircles',
        'ScreenShare',
        'ScreenTrack',
        'ScreenReviews',
        'ScreenCheckout'
      ],
      appStyle: null,
      product: null,
      isButtonDisplayed: true
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    DynamicScreen
  },
  async created() {
    window.addEventListener('resize', this.updateSwiper)
    this.product = (await LandingServices.retriveProductInfo()).data[0]
    this.activeSlideIndex = 0
    
    // if retry payment - scroll and load payments
    this.$nextTick(() => {
      if (this.$route.name === 'Retry') {        
        this.swiper.slideTo(7, 0)
      }
    })
  },
  methods: {
    handleScreenChange() {
      this.activeSlideIndex = this.swiper.realIndex
      if (this.activeSlideIndex !== 7) {        
        this.isButtonDisplayed = true
      }
      this.scrollToTop()
    },
    toggleProceedButton(status) {
      this.isButtonDisplayed = status && this.activeSlideIndex !== 7
    },
    initSwiper(swiper) {
      this.swiper = swiper
      this.updateSwiper()
    },
    updateSwiper() {
      this.calculateHeight()
      setTimeout(() => {
        this.scroll = window.scrollX
        this.sliderHeight = window.innerHeight
        this.swiper.update()
        this.scrollToTop()
      }, 100)
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    calculateHeight() {
      this.appStyle = `min-height: ${window.innerHeight}px`
    },
    async scrollSwiper() {
      const speed = 150
      const slideIndex = this.swiper.slides.length - 1
      this.swiper.slideTo(slideIndex, (this.swiper.slides.length - this.swiper.realIndex) * speed)
      await window.fbq('track', 'Start Trial')
    },
    touchMove(e) {
      e.preventDefault()
    }
  }
}
</script>

<style lang="scss">
@import './styles.scss';
</style>
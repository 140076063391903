<template>
  <router-view/>
</template>

<style>
* {
  box-sizing: border-box;
}
body, html {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, Helvetica, sans-serif;
  padding: 0;
  margin: 0;
}
#app {
  height: 100vh;
  overflow: hidden;
}
</style>

<template>
  <div class="screen" @touchmove="touchMove" :style="screenStyle">
    <div class="screen__description">
      <div class="heart" ref="heart"></div>
      <h1><span>Congratulations!</span></h1>           
      <h2>Your Free Trial is activated.</h2>
      <div class="note-wrapper">
        <div class="button displayed" @click="retrySignIn">OK, Got It</div>
        <p class="note">
          The subscription automatically renews unless auto-renew is turned off at least 24 hours before the end of the current period. 
          The account will be charged for renewal within 24 hours prior to the end of the current period. 
          Subscriptions can be managed by users, and auto-renewal can be turned off in Account Settings after purchase.
          Any unused portion of a free trial period, if offered, will be forfeited when a user purchases a subscription, where applicable. 
        </p>
      </div>
    </div>    
  </div>
</template>

<script>
import LandingServices from '@/services/LandingServices.js'
export default {
  data() {
    return {
      userId: null,
      email : '',
      screenStyle: '',
      product: {}
    }
  },
  props: {
    signInStatus: Boolean
  },
  methods: {
    async retrySignIn() {
      await this.$router.push({ name: 'Instruction' })
    },
    touchMove(e) {
      e.preventDefault()
    },
    calculateHeight() {
      this.screenStyle = `height: ${window.innerHeight}px`
    },
    updateScreen() {
      window.scrollTo(0, 0)
      this.calculateHeight()
    }
  },
  async mounted() {
    this.updateScreen()
    this.product = (await LandingServices.retriveProductInfo()).data[0]
    window.addEventListener('resize', this.updateScreen)
    this.userId = this.$route.params.user_id    
  }
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
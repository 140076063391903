<template>
  <div class="screen screen-instruction" @touchmove="touchMove" :style="screenStyle">
    <div class="screen__description">
      <div class="heart" ref="heart">        
        <h1>Thank you for <br>your purchase!</h1>
      </div>
      <p>👇 Download the app</p>
      <div class="app-download" @click="downloadApp"></div>
      <p>👉 Use the same credentials as on the website to log in.</p>
      <div class="app-sing-in"></div>
      <div class="disclaimer">
        <h5>Disclaimer</h5>
        <p class="small">To be able to access your purchase, you must use the same Apple ID you used to create your account.</p>
      </div>
      <p>🎉 Enjoy your journey!</p>      
    </div>
    <div class="checkout">
      <div class="checkout__legal">
        <p class="checkout__copyright">
          {{ year }} @ All rights reserved. By continuing, you agree to our Privacy Policy and Terms of Service.
        </p>
        <ul>
          <li><a href="https://bpmob.com/safe24/support">Contact Us</a></li>          
          <li class="separator"></li>
          <li><a href="https://bpmob.com/safe24/privacy">Privacy Policy</a></li>     
          <li class="separator"></li>
          <li><a href="https://bpmob.com/safe24/terms">Terms of Service</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: null,
      email : '',
      screenStyle: '',
    }
  },
  props: {
    signInStatus: Boolean,
    paymentStatus: String
  },
  computed: {
    year() {
      return new Date().getFullYear()
    }
  },
  methods: {
    downloadApp() {
      window.location.href = 'https://apps.apple.com/app/id1348649804'
    },
    touchMove(e) {
      e.preventDefault()
    },
    calculateHeight() {
      this.screenStyle = `height: ${window.innerHeight}px`
    },
    updateScreen() {
      window.scrollTo(0, 0)
      this.calculateHeight()
    }
  },
  mounted() {
    this.updateScreen()
    window.addEventListener('resize', this.updateScreen)
    this.userId = this.$route.params.user_id
    this.email = this.$route.params.email
  }
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
export function loadScript (url) {
  return new Promise(function (resolve, reject) {
    let shouldAppend = false
    let el = document.querySelector('script[src="' + url + '"]')
    if (!el) {
      el = document.createElement('script')
      el.type = 'text/javascript'
      el.async = true
      el.src = url
      shouldAppend = true
    }
    else if (el.hasAttribute('data-loaded')) {
      resolve(el)
      return
    }

    el.addEventListener('error', reject);
    el.addEventListener('abort', reject);
    el.addEventListener('load', function loadScriptHandler() {
      el.setAttribute('data-loaded', true);
      resolve(el)
    })

    if (shouldAppend) {
      document.head.appendChild(el)
    }
  })
}

export function delay(ms) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}
<template>
  <div class="screen" @touchmove="touchMove" :style="screenStyle">
    <div class="screen__description">
      <div class="heart" ref="heart">        
        <h1>Not Found</h1>
      </div>
    </div>    
    <div class="screen__description">
      <p @click="goToPage('Main')">Home page</p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goToPage(page) {
      this.$router.push({name: page})
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
<template>
  <div class="screen" @touchmove="touchMove" :style="screenStyle">
    <div class="screen__description">
      <div class="heart" ref="heart">        
        <h1>Find<span>Me</span></h1>
      </div>
    </div>    
    <div class="screen__description">
      <p>Please finish your<br/><span>registration.</span></p>
      <div id="appleid-signin" data-color="black" data-border="true" data-type="sign in" @click="pressSignIn"></div>
    </div>
  </div>
</template>

<script>
import { loadScript } from '@/services/helpers.js'
const appleScriptUrl = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'

export default {
  data() {
    return {
      userId: null,
      screenStyle: '',
      appleObj: {
        clientId : process.env.VUE_APP_APPLE_ID,
        scope : 'name email',
        redirectURI : process.env.VUE_APP_APPLE_REDIRECT,
        state : null
      }
    }
  },
  props: {
    signInStatus: Boolean,
    paymentStatus: String
  },
  methods: {  
    async pressSignIn () {
      await window.ym(process.env.VUE_APP_YANDEX_ID,'reachGoal','apple_signin')
      return true
    },  
    initAppleSignIn(user_id) {
      this.appleObj.state = user_id
      loadScript(appleScriptUrl).then(() => {
        this.$nextTick(() => {
          // eslint-disable-next-line no-undef
          AppleID.auth.init(this.appleObj)
        })
      })
    },   
    touchMove(e) {
      e.preventDefault()
    },
    calculateHeight() {
      this.screenStyle = `height: ${window.innerHeight}px`
    },
    updateScreen() {
      window.scrollTo(0, 0)
      this.calculateHeight()
    }    
  },
  async mounted() {
    this.updateScreen()
    window.addEventListener('resize', this.updateScreen)
    this.userId = this.$route.params.user_id
    this.initAppleSignIn(this.userId)  
    await window.fbq('track', 'Purchase', {currency: "USD", value: 9.99})
  }
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
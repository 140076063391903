<template>
  <div class="screen" @touchmove="touchMove" :style="screenStyle">
    <div class="screen__description">
      <div class="heart" ref="heart">   
        <h1>Find<span>Me</span></h1>   
      </div>   
      <p>Sorry, something went wrong with your payment. 😞</p>
      <p>Please try again.</p>
      <div class="button displayed" @click="retryPayment">Try Again</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: null,
      email : '',
      screenStyle: '',
    }
  },
  props: {
    signInStatus: Boolean,
    paymentStatus: String
  },
  methods: {
    async retryPayment() {
      await this.$router.push({ name: 'Retry'})
    },
    touchMove(e) {
      e.preventDefault()
    },
    calculateHeight() {
      this.screenStyle = `height: ${window.innerHeight}px`
    },
    updateScreen() {
      window.scrollTo(0, 0)
      this.calculateHeight()
    }
  },
  mounted() {
    this.updateScreen()
    window.addEventListener('resize', this.updateScreen)
    this.userId = this.$route.params.user_id
    this.email = this.$route.params.email    
  }
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
import axios from 'axios'
const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const landingUrl = process.env.VUE_APP_LANDING_URL
const application = process.env.VUE_APP_NAME
const product_id = process.env.VUE_APP_PRODUCT_ID

export default {
  validateEmail(email) {
    return api.post('check_email', {
      application: application,
      email,
      parameters: {},
      landing_url: landingUrl
    })
  },
  retriveProductInfo() {
    return api.get(`products/${application}/${product_id}`)
  },
  checkoutCard({email, user_id, token}) {
    return api.post('checkout_card', {
      application,
      email,
      token,
      user_id,
      product_id
    })
  },
  checkoutPaypal({user_id, subscription_id}) {
    return api.post('checkout_paypal', {
      application,
      subscription_id,
      user_id
    })
  },
  checkoutApplePay({user_id, paymentMethod, email}) {
    return api.post('checkout_applepay', {
      application,
      email,
      paymentMethod,
      user_id,
      product_id
    })
  },
}
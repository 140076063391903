<template>
  <div class="screen" @touchmove="touchMove" :style="screenStyle">
    <div class="screen__description">
      <div class="heart" ref="heart">     
        <h1>Find<span>Me</span></h1>
      </div>        
        <p>This Apple ID has already been <br>used to purchase a subscription. <br>Please finish registration using <br>another Apple ID.</p>
        <div class="button displayed" @click="retrySignIn">OK</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: null,
      email : '',
      screenStyle: '',
    }
  },
  props: {
    signInStatus: Boolean
  },
  methods: {
    async retrySignIn() {
      await this.$router.push({ name: 'Payment succeed', params: {user_id: this.$route.params.user_id }})
    },
    touchMove(e) {
      e.preventDefault()
    },
    calculateHeight() {
      this.screenStyle = `height: ${window.innerHeight}px`
    },
    updateScreen() {
      window.scrollTo(0, 0)
      this.calculateHeight()
    }
  },
  mounted() {
    this.updateScreen()
    window.addEventListener('resize', this.updateScreen)
    this.userId = this.$route.params.user_id
  }
}
</script>

<style lang="scss" scoped>
@import './styles.scss';
</style>
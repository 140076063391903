<template>
  <component
    :is="activeScreen"
    :product="activeComponent === 'ScreenCheckout' ? product : undefined "/>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  data() {
    return {}
  },
  props: {
    activeComponent: {
      type: String,
      required: true,
    },
    product: Object
  },
  computed: {
    activeScreen () {
        return defineAsyncComponent(() => import(`@/components/Screens/${this.activeComponent}`))
    }
  }
}
</script>